.floating-chart-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 600px;
    /* Fixed width */
    height: 400px;
    /* Fixed height */
    resize: none;
    /* Remove resize capability */
    overflow: hidden;
}

.floating-chart-header {
    padding: 8px;
    background: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
    cursor: move;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 37px;
}

.floating-chart-title {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.floating-chart-controls {
    display: flex;
    gap: 8px;
}

.floating-chart-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: #64748b;
}

.floating-chart-button:hover {
    color: #0f172a;
}

.floating-chart-content {
    width: calc(100% - 45px);
    height: calc(100% - 72px);
    overflow: hidden;
    margin: 20px 20px 0px 20px;
}

.minimized {
    height: 53px !important;
}