@import url('https://fonts.googleapis.com/css?family=Lora:400|Montserrat:700|Roboto+Condensed:700');

body {
    font-family: 'Lora', serif;
    margin: 0;
    padding: 0;
    background-color: #fff;
}

.page-title-header {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    display: flex;
    flex-direction: column;
    gap: 0px;
    list-style: none;
    margin: 0 0 20px 0;
    line-height: 1.2;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 42px;
    text-align: center !important;
    margin-bottom: 0;
    color: #fff;
    background-color: #e32e29;
    padding: 20px;
}

.page-title-header h1 {
    all: unset;

    margin-top: 50px;
}

.page-title-header h2 {
    all: unset;

    margin-bottom: 50px;
}

.page-title-header h3 {
    all: unset;
}

/* Main Grading Instructions Section */
.grading-instructions {
    font-family: 'Lora', serif;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.625;
    -webkit-text-size-adjust: 100%;
    color: #111;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    padding-top: 70px;
    padding-bottom: 70px;
    background: #f7f7f7;
    text-align: center;
}

/* Header within the grading instructions */
.grading-instructions h3 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.4;
    color: #2d2e33;
    margin: 0 0 20px 0;
}

.questions {
    margin: 70px 80px 10px 80px;
    padding: 0px 40px;
}

/* Hide the right section on smaller screens */
@media (max-width: 850px) {
    .questions {
        margin: 70px 10px 10px 10px;
        padding: 0;
    }
}

.section-block {
    margin-bottom: 30px;
}

.section-header {
    font-family: 'Roboto Condensed';
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
    color: #2d2e33;
}

.question {
    background-color: #fff;
    padding: 15px;
    margin: 10px 0;
}

.question-text-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.question-numbr-text {
    font-family: 'Lora';
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.question-text {
    font-family: 'Lora';
    font-size: 18px;
    margin-bottom: 10px;
}

.options {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

/* Remove blue highlight from radio buttons */
.radio-label input[type="radio"] {
    appearance: none;
    width: 30px;
    height: 30px;
    border: 2px solid #ddd;
    border-radius: 50%;
    margin-right: 10px;
    outline: none;
    /* Removes default focus outline */
    cursor: pointer;
    position: relative;
    transition: border-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Focus state: remove outline but still allow for keyboard accessibility */
.radio-label input[type="radio"]:focus-visible {
    outline: none;
    /* Optional: Customize this if accessibility is needed */
    border-color: #e32e29;
    /* Optional: Red border when focused */
}

/* Small inner circle for selected (checked) state */
.radio-label input[type="radio"]:checked::before {
    content: '';
    width: 22px;
    height: 22px;
    background-color: #e32e29;
    border-radius: 50%;
    position: absolute;
    transition: background-color 0.2s;
}

/* Hover effect */
.radio-label input[type="radio"]:hover {
    border-color: #bbb;
}

/* Radio Label Styling */
.radio-label {
    font-family: 'Lora', serif;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.option-button {
    padding: 10px;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
    margin: 0 5px;
}

.option-button.selected {
    background-color: #e32e29;
    color: white;
}

.submit-button {
    background-color: #e32e29;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    display: block;
    margin: 0px auto;
    font-family: 'Montserrat', sans-serif;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.result-block {
    text-align: center;
}

/* Grading Options (Original Two-Column Layout) */
.grading-options {
    display: flex;
    justify-content: center;
    gap: 60px;
}

/* Individual Grading Option */
.grading-option {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.points-label {
    font-family: 'Open Sans Condensed';
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: red;
}

.grading-description {
    font-family: 'Roboto Condensed';
    font-size: 28px;
}

/* Responsive Design: One column layout for screens < 750px */
@media (max-width: 850px) {
    .grading-options {
        flex-direction: column;
        gap: 15px;
    }

    .grading-option {
        flex: 1 1 100%;
        /* Full width for each element */
    }
}



/* Results Section Layout */
.results-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin: 20px 60px;
}

/* Individual Result Option (Two Columns) */
.results-option {
    flex: 1 1 calc(50% - 20px);
    display: flex;
    flex-direction: column;
    text-align: center;
}

/* Last Result Option Centered */
.results-option:last-child {
    flex: 1 1 100%;
    /* Full width */
    align-self: center;
}

/* Responsive Design for Results Section */
@media (max-width: 850px) {
    .results-options {
        flex-direction: column;
        gap: 15px;
    }

    .results-option {
        flex: 1 1 100%;
        /* Full width for mobile */
    }
}

.user-result-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin: 40px 10px;
}

.user-result-container p {
    font-family: 'Lora', serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.625;
    -webkit-text-size-adjust: 100%;
    color: #111;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.user-result-container h2 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.4;
    color: #2d2e33;
    margin: 0;
}

.results-options-container h3 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.4;
    color: #2d2e33;
    margin: 0 0 20px 0;
    text-align: center;
}

.results-options-container {
    padding: 70px 0px;
    background: #f7f7f7;
}

.done-button {
    background-color: #e32e29;
    color: white;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    transition: background-color 0.3s;
}

.done-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.done-button:hover:enabled {
    background-color: #c11f22;
}

.speaker-level {
    font-size: 24px;
}