body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.container {
  margin-left: 100px;
  /* Slight misalignment */
  margin-top: 50px;
}

h1 {
  font-size: 24px;
  color: #333;
  /* Replace with your primary heading color */
  margin-bottom: 40px;
}

h2 {
  font-size: 20px;
  color: #333;
  /* Replace with your secondary heading color */
  margin-top: 30px;
  margin-bottom: 10px;
}

h3 {
  font-size: 18px;
  color: #333;
  /* Replace with your secondary heading color */
  margin-top: 10px;
  margin-bottom: 10px;
}

ul {
  margin: 20px 0;
  padding-left: 20px;
  list-style-type: disc;
}

li {
  margin-bottom: 10px;
  line-height: 1.5;
}

p {
  margin: 10px 0;
  line-height: 1.6;
}

a {
  color: #2a5820;
}