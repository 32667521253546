/* Main container styles */
/* Move to layout.css */
.financial-planner {
    padding: 20px;
}

.card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.card-header {
    padding: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}

.back-button {
    margin-right: 10px;
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: 600;
    color: #333;
}

.card-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.card-content {
    padding: 20px;
}

/* Move to grid.css */
.grid {
    display: grid;
    gap: 20px;
    width: 100%;
}

.grid-2 {
    grid-template-columns: 1fr 1fr;
}

.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.grid-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

/* Move to forms.css */
.input-group {
    width: 100%;
    margin-bottom: 0;
}

.input-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #333;
}

.input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

/* Move to switch.css */
.switch-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.switch-slider {
    background-color: #2563eb;
}

input:checked+.switch-slider:before {
    transform: translateX(20px);
}

/* Move to forms.css */
.select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    background-color: white;
}

/* Move to typography.css */
.section-header {
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

/* Move to chart.css */
.chart-container {
    height: 400px;
    margin-bottom: 24px;
}

/* Move to alerts.css */
.alert {
    padding: 12px 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    background-color: #f8fafc;
    border: 1px solid #e2e8f0;
}

.alert-info {
    background-color: #ebf5ff;
    border-color: #bfdbfe;
    color: #1e40af;
}

/* Move to utilities.css */
.text-right {
    text-align: right;
}

.text-green {
    color: #16a34a;
}

.text-blue {
    color: #2563eb;
}

.summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 8px 0;
    border-bottom: 1px solid #e2e8f0;
}

.summary-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.summary-label {
    font-weight: 500;
    color: #333;
}

.summary-value {
    font-weight: 600;
    color: #2563eb;
}

.warning {
    color: #dc2626;
    font-weight: 500;
    margin-top: 8px;
}

.section-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

.taxable-investment-account-section {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}