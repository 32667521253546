.home-button {
    background: none;
    border: none;
    padding: 0px;
    cursor: pointer;
    margin: 0px;
}

.home-button img {
    width: 30px;
    height: 38px;
}

.home-button img:hover {
    opacity: 0.4;
    /* Optional: Add a hover effect */
}