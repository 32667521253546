.tax-section {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.tax-title {
    margin-bottom: 20px;
}

.tax-inputs-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;
}

.tax-input-container {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.tax-select {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 5px;
}

.tax-summary {
    margin-top: 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.tax-label {
    color: #666;
    font-weight: 500;
}

.tax-amount {
    font-weight: 600;
    color: #333;
}