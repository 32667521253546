.expense-input-container {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.remove-expense-btn {
    margin: 0px;
    margin-top: 26px;
    background: none;
    border: none;
    color: #ff4444;
    font-size: 22px;
    cursor: pointer;
    padding: 8px;
}

.remove-expense-btn:hover {
    color: #cc0000;
}

.add-expense-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}

.new-expense-input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.add-expense-btn {
    padding: 8px 16px;
    margin: 0px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-expense-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.add-expense-btn:hover:not(:disabled) {
    background-color: #45a049;
}

.summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.summary-item:last-child {
    margin-bottom: 0;
}

.summary-label {
    color: #666;
    font-weight: 500;
}

.summary-value {
    font-weight: 600;
    color: #333;
}

.expense-summary {
    margin-top: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
}

.expenses-section {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}