.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

button {
    font-size: 18px;
    color: #0066cc;
    background: none;
    border: none;
    margin-bottom: 30px;
    cursor: pointer;
    text-align: left;
    padding: 0;
}

button:hover {
    text-decoration: underline;
}

button:focus {
    outline: none;
}

.subscreen-container {
    margin: 20px;
    padding: 20px;
}

.image-gallery {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* grid-template-columns: auto;
    grid-template-rows: auto; */
}

.image-gallery-item {
    display: flex;
    justify-content: top;
    align-items: center;
    gap: 5px;
    flex-direction: column;
}

.image-gallery-item img {
    width: 213px;
    height: 276px;
    object-fit: fill;
}

.image-gallery-item p {
    margin: 0px;
    text-align: center;
}

.links-list {
    display: inline-flex;
    flex-direction: column;
    gap: 5px;
    margin: 0px 0px 10px;
}


/* Contact Us page */

.social-links-container {
    padding: 0px;
}

.social-links-list {
    display: flex;
    gap: 1rem;
}

.social-link {
    color: #4b5563;
    transition: color 0.3s ease;
}

.social-link:hover {
    color: #1f2937;
}

.social-link svg {
    width: 24px;
    height: 24px;
}