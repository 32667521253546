/* Header container */
.tm-header {
    background-color: #111;
    padding: 0;
}

.uk-navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 80px;
}

/* Left: Logo */
.uk-navbar-left {
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 25px;
}

.uk-logo {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: white;
    text-decoration: none;
}

/* Right: Links and Booking Button aligned horizontally */
.uk-navbar-right {
    display: flex;
    align-items: center;
    margin-right: 40px;
    gap: 40px;
}

/* Hide the right section on smaller screens */
@media (max-width: 850px) {
    .uk-navbar-right {
        display: none;
        /* Hide on small screens */
    }
}

/* Navigation links styling */
.navbar-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

.navbar-links a {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.625;
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    height: 80px;
    display: flex;
    align-items: center;
}

.navbar-links a:hover {
    color: #e32e29;
}

/* Booking button styling */
.booking-button {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 700;
    background-color: #e32e29;
    color: white;
    padding: 12px 22px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: 0.1s ease-in-out;
}

.booking-button:hover {
    background-color: #c11f22;
}