/* YearlyBreakdown Component Styles */
.yearly-breakdown-section {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.yearly-breakdown-section .section-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
}

.table-container {
    overflow-x: auto;
    margin-bottom: 24px;
    max-height: 70vh;
    /* Add max height to enable scrolling */
    position: relative;
    /* For sticky header positioning */
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.table th {
    font-weight: 500;
    background-color: #f9fafb;
    position: sticky;
    top: 0;
    z-index: 10;
    /* Add box-shadow for visual separation */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table td {
    color: #333;
}

.collapsible-header {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 12px 0;
}

.collapsible-content {
    display: none;
}

.collapsible-content.open {
    display: block;
}

.inflation-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inflation-default {
    font-size: 12px;
    color: #666;
    font-weight: normal;
}

.inflation-cell {
    width: 120px;
    padding: 4px;
}

.inflation-cell .input-group {
    margin: 0;
}

.inflation-cell .input {
    padding: 4px 8px;
    font-size: 14px;
}

.inflation-cell .input-label {
    display: none;
}

.inflation-cell .input-group {
    margin: 0;
}